// fonts
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// color variants

@import 'theme1.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| UTILITY ||============================== //
.h-full {
    height: 100%;
}

.absolute {
    position: absolute;
}

.inset-0 {
    inset: 0;
}

.fab-tooltip {
    width: max-content;
    background-color: transparent;
    color: $primaryMain;
    box-shadow: none;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// ==============================|| Banner Slick Slider ||============================== //

.banner-slider .slick-prev,
.banner-slider .slick-next {
    z-index: 10;
}

.banner-slider .slick-prev {
    inset-inline-start: 16px;
}

.banner-slider[dir='rtl'] .slick-next {
    left: auto;
}

.banner-slider[dir='rtl'] .slick-prev:before {
    content: '←';
}

.banner-slider[dir='rtl'] .slick-next:before {
    content: '→';
}

.banner-slider[dir='rtl'].banner-slider .slick-dots {
    inset-inline-end: auto;
    inset-inline-start: 16px;
}

.banner-slider .slick-next {
    inset-inline-end: 16px;
}

.banner-slider .slick-dots {
    width: auto;
    bottom: 16px;
    inset-inline-end: 16px;
}

.banner-slider .slick-dots li {
    margin: 0 2px;
}

.banner-slider .slick-dots li button:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 50%;
}

.banner-slider .slick-dots li.slick-active button:before {
    color: white;
}

.banner-slider a,
.banner-slider .slick-slider,
.banner-slider .slick-slide,
.banner-slider .slick-list,
.banner-slider .slick-track,
.banner-slider .slick-track div,
.banner-slider .slick-track div img {
    height: 100%;
    border-radius: 8px;
}

// ==============================|| LIGHT BOX ||============================== //
// .slick-arrow:before {
//     color: $grey500;
// }

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $neutral500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $neutral500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
