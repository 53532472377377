// paper & background
$paper: #ffffff;

// primary
$primary100: #e6ecf2;
$primary200: #ccd8e5; // light
$primary300: #a7b8cc;
$primary400: #165db2; // main
$primary500: #124c91;
$primary600: #0f3f79; // dark
$primary700: #033066;
$primary800: #00234d;
$primaryLight: #ccd8e5;
$primaryMain: #165db2;
$primaryDark: #0f3f79;

// secondary
$secondary100: #e6f1f2;
$secondary200: #cfe3e5;
$secondary300: #abd2d6;
$secondary400: #03bed0; // light
$secondary500: #0297a6;
$secondary600: #007e8f; // main
$secondary700: #005a66;
$secondary800: #003f47; // dark
$secondaryLight: #03bed0;
$secondaryMain: #007e8f;
$secondaryDark: #003f47;

// orange
$orange100: #ffefe6;
$orange200: #ffdfcc;
$orange300: #ffae80; // light
$orange400: #ff7e33;
$orange500: #ff5e00; // main
$orange600: #cc2b00;
$orange700: #990000; // dark
$orange800: #330000;
$orangeLight: #ffae80;
$orangeMain: #ff7e33;
$orangeDark: #990000;

// success
$success100: #e7f8f0;
$success300: #92deba; // light
$success500: #12b76a; // main
$success600: #0c7d49; // dark
$successLight: #92deba;
$successMain: #12b76a;
$successDark: #0c7d49;

// warning
$warning100: #fef4e6;
$warning300: #fbcc8e; // light
$warning500: #f79009; // main
$warning600: #a25f06; // dark
$warningLight: #fbcc8e;
$warningMain: #f79009;
$warningDark: #a25f06;

// error
$error100: #feeceb;
$error300: #f8a9a3; // light
$error500: #f04438; // main
$error600: #d51d10; // dark
$errorLight: #f8a9a3;
$errorMain: #f04438;
$errorDark: #d51d10;

// grey (neutral)
$neutral50: #eef1fa;
$neutral100: #e0e3ec;
$neutral200: #c4c7d0;
$neutral300: #a9abb4;
$neutral400: #8b8d99; // light
$neutral500: #626878;
$neutral600: #505466; // main
$neutral700: #272c37; // dark
$neutral800: #141922;
$neutralLight: #8b8d99;
$neutralMain: #505466;
$neutralDark: #272c37;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkPaper: #111936; // level 4

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primary100: $primary100;
    primary200: $primary200;
    primary300: $primary300;
    primary400: $primary400;
    primary500: $primary500;
    primary600: $primary600;
    primary700: $primary700;
    primary800: $primary800;
    primaryLight: $primaryLight;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;

    // secondary
    secondary100: $secondary100;
    secondary200: $secondary200;
    secondary300: $secondary300;
    secondary400: $secondary400;
    secondary500: $secondary500;
    secondary600: $secondary600;
    secondary700: $secondary700;
    secondary800: $secondary800;
    secondaryLight: $secondaryLight;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;

    // orange
    orange100: $orange100;
    orange200: $orange200;
    orange300: $orange300;
    orange400: $orange400;
    orange500: $orange500;
    orange600: $orange600;
    orange700: $orange700;
    orange800: $orange800;
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // success
    success100: $success100;
    success300: $success300;
    success500: $success500;
    success600: $success600;
    successLight: $successLight;
    successMain: $successMain;
    successDark: $successDark;

    // warning
    warning100: $warning100;
    warning300: $warning300;
    warning500: $warning500;
    warning600: $warning600;
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // error
    error100: $error100;
    error300: $error300;
    error500: $error500;
    error600: $error600;
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // grey (neutral)
    neutral50: $neutral50;
    neutral100: $neutral100;
    neutral200: $neutral200;
    neutral300: $neutral300;
    neutral400: $neutral400;
    neutral500: $neutral500;
    neutral600: $neutral600;
    neutral700: $neutral700;
    neutral800: $neutral800;
    neutralLight: $neutralLight;
    neutralMain: $neutralMain;
    neutralDark: $neutralDark;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
}
